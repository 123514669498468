<template>
    <svg
        aria-hidden="true"
        class="absolute"
        fill="currentColor"
        height="0"
        width="0"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <symbol id="icon-digital_download" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-online_course" viewBox="0 0 24 24">
            <path
                d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z"
            />
            <path
                d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z"
            />
            <path
                d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z"
            />
        </symbol>

        <symbol id="icon-bundle" viewBox="0 0 511.99 511.99">
            <path
                d="M498.931,100.434L268.48,29.704c-3.129-0.96-6.466-0.989-9.61-0.084l-90.293,25.997l249.375,81.225v50.673
			c0,4.551-3.07,8.531-7.472,9.686l-92.536,24.275c-3.003,0.788-6.2,0.138-8.658-1.758c-2.458-1.896-3.897-4.824-3.897-7.928
			v-46.078h-0.102L61.73,86.381L14.864,99.874c-2.915,0.347-5.732,1.428-8.142,3.24c-3.889,2.926-6.281,7.379-6.648,12.19
			c-0.02,0.22-0.027,0.444-0.039,0.666C0.028,116.176,0,116.379,0,116.586v285.001c0,7.51,4.968,14.113,12.183,16.196
			l220.831,63.722c3.505,1.601,7.4,1.959,11.122,1.021l255.128-64.337c7.488-1.888,12.735-8.623,12.735-16.344V116.843
			C512,109.14,506.746,102.236,498.931,100.434z M206.08,278.91c0,7.719-7.418,13.273-14.825,11.098l-38.117-11.189
			c-4.925-1.446-8.309-5.965-8.309-11.098v-37.142c0-7.719,7.418-13.273,14.825-11.098l38.117,11.189
			c4.925,1.446,8.309,5.965,8.309,11.098V278.91z M479.988,379.182c0,5.756-3.914,10.775-9.497,12.177l-52.541,14.42
			c-3.752,0.943-7.729,0.102-10.78-2.277c-3.051-2.379-4.834-6.032-4.834-9.902v-52.728c0-5.756,3.914-10.775,9.497-12.177
			l52.541-14.42c3.752-0.943,7.729-0.102,10.78,2.277s4.834,6.032,4.834,9.902V379.182z"
            />
        </symbol>

        <symbol id="icon-membership" viewBox="0 0 20 20">
            <path
                d="M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm8 1a3 3 0 1 0 0-5 3 3 0 0 0 0 5zM2 16a1 1 0 0 1-1-1 6 6 0 0 1 12 0l-1 1a10 10 0 0 1-5 2 10 10 0 0 1-5-2zm13 0h-1v-1a7 7 0 0 0-1-4 5 5 0 0 1 6 3 1 1 0 0 1-1 1 7 7 0 0 1-3 1z"
            />
        </symbol>

        <symbol id="icon-overview" viewBox="0 0 20 20">
            <path d="M2 10a8 8 0 0 1 8-8v8h8a8 8 0 1 1-16 0z" />
            <path d="M12 2a8 8 0 0 1 6 6h-6V2z" />
        </symbol>

        <symbol
            id="icon-plus-circle"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-home-solid" viewBox="0 0 20 20">
            <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
            />
        </symbol>

        <symbol
            id="icon-building-storefront-solid"
            fill="currentColor"
            viewBox="0 0 20 20"
        >
            <path
                d="M2.879 7.121A3 3 0 007.5 6.66a2.997 2.997 0 002.5 1.34 2.997 2.997 0 002.5-1.34 3 3 0 104.622-3.78l-.293-.293A2 2 0 0015.415 2H4.585a2 2 0 00-1.414.586l-.292.292a3 3 0 000 4.243zM3 9.032a4.507 4.507 0 004.5-.29A4.48 4.48 0 0010 9.5a4.48 4.48 0 002.5-.758 4.507 4.507 0 004.5.29V16.5h.25a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75v-3.5a.75.75 0 00-.75-.75h-2.5a.75.75 0 00-.75.75v3.5a.75.75 0 01-.75.75h-4.5a.75.75 0 010-1.5H3V9.032z"
            />
        </symbol>

        <symbol id="icon-star" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-chart-pie-solid" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-chart-pie-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-stack" viewBox="0 0 20 20">
            <path
                d="M5 4h10a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2zm-4 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-6zm2-3a2 2 0 0 1 2-2h10l2 2a4 4 0 0 0 0-1H3z"
            />
        </symbol>

        <symbol id="icon-square-3-stack-3d-solid" viewBox="0 0 24 24">
            <path
                d="M11.644 1.59a.75.75 0 01.712 0l9.75 5.25a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.712 0l-9.75-5.25a.75.75 0 010-1.32l9.75-5.25z"
            />
            <path
                d="M3.265 10.602l7.668 4.129a2.25 2.25 0 002.134 0l7.668-4.13 1.37.739a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.71 0l-9.75-5.25a.75.75 0 010-1.32l1.37-.738z"
            />
            <path
                d="M10.933 19.231l-7.668-4.13-1.37.739a.75.75 0 000 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 000-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 01-2.134-.001z"
            />
        </symbol>

        <symbol id="icon-archive-2-solid" viewBox="0 0 24 24">
            <path
                d="M22 20V7l-2-4H4L2 7.004V20a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1M5.236 5h13.528l1 2H4.237zM9 11h6v2H9z"
            />
        </symbol>

        <symbol id="icon-circle-stack-solid" viewBox="0 0 24 24">
            <path
                d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z"
            />
            <path
                d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z"
            />
            <path
                d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z"
            />
            <path
                d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 19.664 9.315 20.25 12 20.25z"
            />
        </symbol>

        <symbol id="icon-squares" viewBox="0 0 20 20">
            <path
                clip-rule="evenodd"
                d="M4 2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H4zm9-9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-3zm0 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2h-3z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-shopping-bag-solid" viewBox="0 0 20 20">
            <path
                clip-rule="evenodd"
                d="M6 5v1H5a2 2 0 0 0-2 2l-1 9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2l-1-9a2 2 0 0 0-2-2h-1V5a4 4 0 0 0-8 0zm4-2a3 3 0 0 0-2 2v1h5V5a3 3 0 0 0-3-2zm-2 7a3 3 0 0 0 5 0V9a1 1 0 0 1 1 0v1a4 4 0 0 1-8 0V9a1 1 0 0 1 2 0v1z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-shopping-bag-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-shopping-cart-solid" viewBox="0 0 24 24">
            <path
                d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
            />
        </symbol>

        <symbol
            id="icon-shopping-cart-outline"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-user-group" viewBox="0 0 20 20">
            <path
                d="M10 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM6 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-5 7a3 3 0 0 1 4-4 6 6 0 0 0-1 4v1a5 5 0 0 1-3-1zm15 1a5 5 0 0 0 3-1 3 3 0 0 0-4-4 6 6 0 0 1 1 4 2 2 0 0 1 0 1zm2-8a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM5 16a1 1 0 0 1 0-1 5 5 0 0 1 10 0 1 1 0 0 1 0 1 7 7 0 0 1-5 2 7 7 0 0 1-5-2z"
            />
        </symbol>
        <!--        <symbol id="icon-users" viewBox="924 578 200 200">-->
        <!--            <path-->
        <!--                d="M985 639c0 14-10 25-22 25s-21-11-21-25 9-25 21-25 22 11 22 25z"-->
        <!--            />-->
        <!--            <path-->
        <!--                d="m988 684 5-2c-4-5-7-11-8-17 0-3-4-3-6-1a24 24 0 0 1-32 0c-1-1-3-2-5-1-8 5-15 14-18 23a9 9 0 0 0 9 11h38c5-5 10-10 17-13zm75-45c0 14 10 25 22 25s21-11 21-25-9-25-21-25-22 11-22 25z"-->
        <!--            />-->
        <!--            <path-->
        <!--                d="m1060 684-5-2c4-5 7-11 8-17 0-3 4-3 6-1a24 24 0 0 0 32 0c1-1 3-2 5-1 8 5 15 14 18 23a9 9 0 0 1-9 11h-38c-5-5-10-10-17-13zm22 41c-4-14-13-27-26-35-2-1-6-1-8 1-7 6-15 10-24 10s-18-4-25-10c-2-2-5-3-8-1-13 8-22 21-27 35a13 13 0 0 0 13 17h93a13 13 0 0 0 12-17z"-->
        <!--            />-->
        <!--            <path-->
        <!--                d="M1057 653c0 21-15 39-33 39s-33-18-33-39c0-22 15-39 33-39s33 17 33 39z"-->
        <!--            />-->
        <!--        </symbol>-->
        <symbol id="icon-users" viewBox="0 0 20 20">
            <path
                d="M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm8 1a3 3 0 1 0 0-5 3 3 0 0 0 0 5zM2 16a1 1 0 0 1-1-1 6 6 0 0 1 12 0l-1 1a10 10 0 0 1-5 2 10 10 0 0 1-5-2zm13 0h-1v-1a7 7 0 0 0-1-4 5 5 0 0 1 6 3 1 1 0 0 1-1 1 7 7 0 0 1-3 1z"
            />
        </symbol>

        <symbol id="icon-user-solid" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-user-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-user-circle-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-document-duplicate-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-eye-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>
        <symbol
            id="icon-material-symbols-share"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363t.075-.337l-7.05-4.1q-.425.375-.95.588T6 15q-1.25 0-2.125-.875T3 12q0-1.25.875-2.125T6 9q.575 0 1.1.213t.95.587l7.05-4.1q-.05-.15-.075-.337T15 5q0-1.25.875-2.125T18 2q1.25 0 2.125.875T21 5q0 1.25-.875 2.125T18 8q-.575 0-1.1-.212t-.95-.588L8.9 11.3q.05.15.075.338T9 12q0 .175-.025.363T8.9 12.7l7.05 4.1q.425-.375.95-.587T18 16q1.25 0 2.125.875T21 19q0 1.25-.875 2.125T18 22"
            />
        </symbol>

        <symbol
            id="icon-material-symbols-folder-zip-outline"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                d="M16 12v-2h2v2h-2Zm0 2h-2v-2h2v2Zm0 2v-2h2v2h-2Zm-4.825-8l-2-2H4v12h10v-2h2v2h4V8h-4v2h-2V8h-2.825ZM4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h6l2 2h8q.825 0 1.413.588T22 8v10q0 .825-.588 1.413T20 20H4Zm0-2V6v12Z"
            />
        </symbol>

        <symbol
            id="icon-play-circle-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-play-circle-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-symbols-play-arrow-outline" viewBox="0 0 24 24">
            <path
                d="M9.525 18.025q-.5.325-1.012.038T8 17.175V6.825q0-.6.513-.888t1.012.038l8.15 5.175q.45.3.45.85t-.45.85l-8.15 5.175ZM10 12Zm0 3.35L15.25 12L10 8.65v6.7Z"
                fill="currentColor"
            />
        </symbol>

        <symbol
            id="icon-material-symbols-attach-file-rounded"
            viewBox="0 0 24 24"
        >
            <path
                d="M11.5 22q-2.3 0-3.9-1.6T6 16.5V6q0-1.65 1.175-2.825T10 2q1.65 0 2.825 1.175T14 6v9.5q0 1.05-.725 1.775T11.5 18q-1.05 0-1.775-.725T9 15.5V6.75q0-.325.213-.537T9.75 6q.325 0 .537.213t.213.537v8.75q0 .425.288.713t.712.287q.425 0 .713-.288t.287-.712V6q0-1.05-.725-1.775T10 3.5q-1.05 0-1.775.725T7.5 6v10.5q0 1.65 1.175 2.825T11.5 20.5q1.65 0 2.825-1.175T15.5 16.5V6.75q0-.325.213-.537T16.25 6q.325 0 .537.213T17 6.75v9.75q0 2.3-1.6 3.9T11.5 22Z"
                fill="currentColor"
            />
        </symbol>

        <symbol
            id="icon-pause-circle-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-pause-circle-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-clock-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-banknotes-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-banknotes-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
            <path
                clip-rule="evenodd"
                d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
                fill-rule="evenodd"
            />
            <path
                d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"
            />
        </symbol>

        <symbol
            id="icon-wallet-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-wallet-solid" viewBox="0 0 24 24">
            <path
                d="M2.273 5.625A4.483 4.483 0 015.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 3H5.25a3 3 0 00-2.977 2.625zM2.273 8.625A4.483 4.483 0 015.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 6H5.25a3 3 0 00-2.977 2.625zM5.25 9a3 3 0 00-3 3v6a3 3 0 003 3h13.5a3 3 0 003-3v-6a3 3 0 00-3-3H15a.75.75 0 00-.75.75 2.25 2.25 0 01-4.5 0A.75.75 0 009 9H5.25z"
            />
        </symbol>

        <symbol id="icon-receipt-percent" viewBox="0 0 20 20">
            <path
                clip-rule="evenodd"
                d="M5 1a41 41 0 0 1 10 0c1 0 2 1 2 3v14a1 1 0 0 1-1 1l-3-1-3 1-3-1-3 1a1 1 0 0 1-1-1V4c0-2 1-3 2-3zm9 6a1 1 0 0 0-1-1l-7 7a1 1 0 1 0 1 1l7-7zM9 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-credit-card" viewBox="0 0 24 24">
            <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
            <path
                clip-rule="evenodd"
                d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-paypal" viewBox="0 0 24 24">
            <path
                d="M19.03 1.81C17.93.54 15.96 0 13.43 0H6.1c-.52 0-.96.38-1.04.9L2.01 20.6c-.06.39.23.74.62.74h4.53L8.29 14l-.03.23c.08-.52.52-.9 1.03-.9h2.16c4.22 0 7.53-1.74 8.5-6.8l.07-.43c-.12-.07-.12-.07 0 0 .3-1.87 0-3.14-1-4.29z"
            />
            <path
                d="M10.03 5.42a.9.9 0 0 1 .4-.09h5.74c.68 0 1.32.05 1.9.14a7.85 7.85 0 0 1 1.16.28c.28.1.55.21.8.35.28-1.87 0-3.14-1-4.29C17.93.54 15.96 0 13.43 0H6.1c-.52 0-.96.38-1.04.9L2.01 20.6c-.06.39.23.74.62.74h4.53L9.52 6.12a.93.93 0 0 1 .5-.7z"
            />
            <path
                d="M20.31 6.84C19 12 16 13.5 11.81 13.64H9.65c-.51 0-.95.38-1.03.9l-1.41 9.11a.56.56 0 0 0 .54.65h3.82a.92.92 0 0 0 .9-.79l.04-.2.72-4.63.05-.26a.92.92 0 0 1 .9-.78h.57c3.7 0 6.6-1.53 7.44-5.95.36-1.85.17-3.39-.76-4.47a3.65 3.65 0 0 0-1.04-.82 12.4 12.4 0 0 1-.08.44z"
            />
        </symbol>

        <symbol id="icon-chart-bar-solid" viewBox="0 0 24 24">
            <path
                d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"
            />
        </symbol>

        <symbol
            id="icon-chart-bar-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-lock-closed-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-settings" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M11.83 2.25c-.92 0-1.7.66-1.85 1.57l-.1.55a.8.8 0 0 1-.51.6 7.45 7.45 0 0 0-.48.2.8.8 0 0 1-.8-.06l-.45-.33a1.88 1.88 0 0 0-2.41.2l-.25.25a1.88 1.88 0 0 0-.2 2.41l.33.46a.8.8 0 0 1 .06.8 7.45 7.45 0 0 0-.2.47.8.8 0 0 1-.6.52l-.55.09a1.88 1.88 0 0 0-1.57 1.85v.34c0 .92.66 1.7 1.57 1.85l.55.1c.28.04.5.24.6.51l.2.48a.8.8 0 0 1-.06.8l-.33.45a1.88 1.88 0 0 0 .2 2.41l.25.25c.65.64 1.67.73 2.41.2l.46-.33a.8.8 0 0 1 .8-.06 8 8 0 0 0 .47.2c.27.1.47.32.52.6l.09.55c.15.9.93 1.57 1.85 1.57h.34c.92 0 1.7-.66 1.85-1.57l.1-.55a.8.8 0 0 1 .51-.6 7.52 7.52 0 0 0 .48-.2.8.8 0 0 1 .8.06l.45.33a1.88 1.88 0 0 0 2.41-.2l.25-.25c.64-.65.73-1.67.2-2.41l-.33-.46a.8.8 0 0 1-.06-.8 8 8 0 0 0 .2-.47.8.8 0 0 1 .6-.52l.55-.09a1.88 1.88 0 0 0 1.57-1.85v-.34c0-.92-.66-1.7-1.57-1.85l-.55-.1a.8.8 0 0 1-.6-.51 7.5 7.5 0 0 0-.2-.48.8.8 0 0 1 .06-.8l.33-.45a1.88 1.88 0 0 0-.2-2.41l-.25-.25a1.88 1.88 0 0 0-2.41-.2l-.46.33a.8.8 0 0 1-.8.06 7.46 7.46 0 0 0-.47-.2.8.8 0 0 1-.52-.6l-.09-.55a1.88 1.88 0 0 0-1.85-1.57h-.34zm.17 13.5a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-integration" viewBox="0 0 20 20">
            <path d="M0 0h20v20H0z" fill="none" />
            <path
                d="M16 13a3 3 0 0 0-2.8 2H9c0-.7-.2-1.3-.5-1.8l7.1-7.3.9.1C17.9 6 19 4.9 19 3.5S17.9 1 16.5 1 14 2.1 14 3.5l.2 1-7 7.2c-.6-.5-1.4-.7-2.2-.7V6.8A3 3 0 0 0 7 4c0-1.7-1.3-3-3-3S1 2.3 1 4a3 3 0 0 0 2 2.8v4.7a4 4 0 0 0-2 3.4 4 4 0 0 0 4 4c1.5 0 2.8-.8 3.4-2h4.7a3 3 0 0 0 2.8 2 3 3 0 0 0 3-3C19 14.3 17.6 13 16 13z"
            />
        </symbol>

        <symbol id="icon-puzzle-piece-solid" viewBox="0 0 24 24">
            <path
                d="M11.25 5.337c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.036 1.007-1.875 2.25-1.875S15 2.34 15 3.375c0 .369-.128.713-.349 1.003-.215.283-.401.604-.401.959 0 .332.278.598.61.578 1.91-.114 3.79-.342 5.632-.676a.75.75 0 01.878.645 49.17 49.17 0 01.376 5.452.657.657 0 01-.66.664c-.354 0-.675-.186-.958-.401a1.647 1.647 0 00-1.003-.349c-1.035 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401.31 0 .557.262.534.571a48.774 48.774 0 01-.595 4.845.75.75 0 01-.61.61c-1.82.317-3.673.533-5.555.642a.58.58 0 01-.611-.581c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.035-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959a.641.641 0 01-.658.643 49.118 49.118 0 01-4.708-.36.75.75 0 01-.645-.878c.293-1.614.504-3.257.629-4.924A.53.53 0 005.337 15c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.036 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.369 0 .713.128 1.003.349.283.215.604.401.959.401a.656.656 0 00.659-.663 47.703 47.703 0 00-.31-4.82.75.75 0 01.83-.832c1.343.155 2.703.254 4.077.294a.64.64 0 00.657-.642z"
            />
        </symbol>

        <symbol
            id="icon-ellipsis-vertical"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-help" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M19.449 8.448L16.388 11a4.52 4.52 0 010 2.002l3.061 2.55a8.275 8.275 0 000-7.103zM15.552 19.45L13 16.388a4.52 4.52 0 01-2.002 0l-2.55 3.061a8.275 8.275 0 007.103 0zM4.55 15.552L7.612 13a4.52 4.52 0 010-2.002L4.551 8.45a8.275 8.275 0 000 7.103zM8.448 4.55L11 7.612a4.52 4.52 0 012.002 0l2.55-3.061a8.275 8.275 0 00-7.103 0zm8.657-.86a9.776 9.776 0 011.79 1.415 9.776 9.776 0 011.414 1.788 9.764 9.764 0 010 10.211 9.777 9.777 0 01-1.415 1.79 9.777 9.777 0 01-1.788 1.414 9.764 9.764 0 01-10.212 0 9.776 9.776 0 01-1.788-1.415 9.776 9.776 0 01-1.415-1.788 9.764 9.764 0 010-10.212 9.774 9.774 0 011.415-1.788A9.774 9.774 0 016.894 3.69a9.764 9.764 0 0110.211 0zM14.121 9.88a2.985 2.985 0 00-1.11-.704 3.015 3.015 0 00-2.022 0 2.985 2.985 0 00-1.11.704c-.326.325-.56.705-.704 1.11a3.015 3.015 0 000 2.022c.144.405.378.785.704 1.11.325.326.705.56 1.11.704.652.233 1.37.233 2.022 0a2.985 2.985 0 001.11-.704c.326-.325.56-.705.704-1.11a3.016 3.016 0 000-2.022 2.985 2.985 0 00-.704-1.11z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-trash" fill="currentColor" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-trash-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-file-copy-outline"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M9.115 17q-.69 0-1.152-.462q-.463-.463-.463-1.153V4.615q0-.69.463-1.152Q8.425 3 9.115 3h7.77q.69 0 1.152.463q.463.462.463 1.152v10.77q0 .69-.462 1.153q-.463.462-1.153.462zm0-1h7.77q.23 0 .423-.192q.192-.193.192-.423V4.615q0-.23-.192-.423Q17.115 4 16.885 4h-7.77q-.23 0-.423.192q-.192.193-.192.423v10.77q0 .23.192.423q.193.192.423.192m-3 4q-.69 0-1.152-.462q-.463-.463-.463-1.153V6.615h1v11.77q0 .23.192.423q.193.192.423.192h8.77v1zM8.5 16V4z"
            />
        </symbol>

        <symbol id="icon-handshake-solid" viewBox="0 0 24 24">
            <path
                d="M12.835 3.161a.75.75 0 0 0 .147-.148h2.562a5.376 5.376 0 0 1 .58 0h.038a3.817 3.817 0 0 1 1.646.368a5.225 5.225 0 0 1 2.34 1.822a5.208 5.208 0 0 1 .804 4.348l-3.281-3.237a.75.75 0 0 0-.527-.216h-4.775a.75.75 0 0 0-.423.152l-1.884 1.428a.969.969 0 0 1-1.358-.184a.952.952 0 0 1 .181-1.34zM7.899 14.235l-.014.013l-.974.968l-.013.012a.906.906 0 0 1-1.261-.012a.892.892 0 0 1 0-1.267l.974-.968a.906.906 0 0 1 1.275 0a.892.892 0 0 1 .013 1.254m-.291 1.698a.892.892 0 0 0 .013 1.254c.352.35.923.35 1.275 0l.974-.968a.892.892 0 0 0-.14-1.38a.906.906 0 0 0-1.122.1l-.013.014l-.974.967zM5.5 11.407a.892.892 0 0 1 0 1.267l-.974.968a.906.906 0 0 1-1.275 0a.892.892 0 0 1 0-1.267l.974-.968a.906.906 0 0 1 1.275 0m6.353 5.517a.892.892 0 0 1 0 1.267l-.974.968a.906.906 0 0 1-1.275 0a.892.892 0 0 1-.172-1.028l.001-.002a.894.894 0 0 1 .171-.237l.974-.968a.906.906 0 0 1 1.275 0M5.344 4.83a6.179 6.179 0 0 1 5.112-1.749L7.98 4.958a2.452 2.452 0 0 0-.466 3.448a2.473 2.473 0 0 0 3.454.467l1.684-1.275h4.185l3.507 3.46a.497.497 0 0 0 .036.04l1.15 1.15a1.439 1.439 0 0 1-1.936 2.124l-.096-.096a.501.501 0 0 0-.06-.052l-1.093-1.092a.5.5 0 1 0-.707.707l1.15 1.15c.042.041.084.081.128.119l.044.044a1.019 1.019 0 1 1-1.441 1.441l-.17-.169a.5.5 0 0 0-.853.363a.498.498 0 0 0 .147.365l.223.223a.943.943 0 1 1-1.333 1.333h-.001l-.012-.013l-.21-.21a.497.497 0 0 0-.707 0a.5.5 0 0 0 0 .707l.218.219a.96.96 0 0 1-1.35 1.367l-1.431-1.36l.525-.522a1.884 1.884 0 0 0 0-2.677a1.907 1.907 0 0 0-1.429-.552a1.881 1.881 0 0 0-.556-1.42a1.906 1.906 0 0 0-1.428-.552a1.88 1.88 0 0 0-.556-1.419a1.913 1.913 0 0 0-1.844-.489a1.88 1.88 0 0 0-.541-1.085a1.914 1.914 0 0 0-2.514-.158A6.096 6.096 0 0 1 5.344 4.83"
            />
        </symbol>

        <symbol id="icon-circular-connection-solid" viewBox="0 0 48 48">
            <g fill="none" stroke="currentColor" stroke-width="4">
                <path
                    d="M13.5 39.37A16.927 16.927 0 0 0 24 43c3.963 0 7.61-1.356 10.5-3.63M19 9.747C12.051 11.882 7 18.351 7 26c0 1.925.32 3.775.91 5.5M29 9.747C35.949 11.882 41 18.351 41 26c0 1.925-.32 3.775-.91 5.5"
                />
                <path
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M43 36c0 1.342-.528 2.56-1.388 3.458A5 5 0 1 1 43 36m-28 0c0 1.342-.528 2.56-1.388 3.458A5 5 0 1 1 15 36M29 9c0 1.342-.528 2.56-1.388 3.458A5 5 0 1 1 29 9"
                />
            </g>
        </symbol>

        <symbol id="icon-megaphone-solid" viewBox="0 0 24 24">
            <path
                d="M17.212 2.237a.75.75 0 0 0-1.423-.474l-.75 2.249a.75.75 0 0 0 1.423.474zm4.568-.017a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 1 1-1.06-1.06l2.5-2.5a.75.75 0 0 1 1.06 0m-8.91 1.52a2.325 2.325 0 0 0-3.794.577L3.178 16.56a1.8 1.8 0 0 0 .387 2.09l1.439 1.358a1.8 1.8 0 0 0 1.964.336l1.417-.628a4 4 0 0 0 7.314-3.24l3.42-1.516a2.325 2.325 0 0 0 .758-3.712zm1.457 13.342a2.5 2.5 0 0 1-4.569 2.024zM19 8.249a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75"
            />
        </symbol>

        <symbol
            id="icon-pencil-square-outline"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-information-circle-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-exclamation-triangle-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-bell-alert-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z"
            />
            <path
                clip-rule="evenodd"
                d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-product-basic-info"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                d="m10 21l4-4h6q.825 0 1.413.588T22 19q0 .825-.588 1.413T20 21H10Zm8.3-12.075l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L18.3 8.925ZM3 21q-.425 0-.713-.288T2 20v-2.825q0-.2.075-.388t.225-.337l10.3-10.3l4.25 4.25l-10.3 10.3q-.15.15-.337.225T5.825 21H3Z"
            />
        </symbol>

        <symbol
            id="icon-product-images"
            fill="currentColor"
            viewBox="0 0 512 512"
        >
            <path
                d="M457.6 140.2l-82.5-4-4.8-53.8c-1-11.3-11.1-19.2-22.9-18.3L51.5 88.4c-11.8 1-20.3 10.5-19.4 21.7l21.2 235.8c1 11.3 11.2 19.2 22.9 18.3l15-1.2-2.4 45.8c-.6 12.6 9.2 22.8 22.4 23.5L441.3 448c13.2.6 24.1-8.6 24.8-21.2L480 163.5c.6-12.5-9.3-22.7-22.4-23.3zm-354.9 5.3l-7.1 134.8L78.1 305 62 127v-.5-.5c1-5 4.4-9 9.6-9.4l261-21.4c5.2-.4 9.7 3 10.5 7.9 0 .2.3.2.3.4 0 .1.3.2.3.4l2.7 30.8-219-10.5c-13.2-.4-24.1 8.8-24.7 21.3zm334 236.9l-84.8-99.5-37.4 34.3-69.2-80.8-122.7 130.7L133 168v-.4c1-5.4 6.2-9.3 11.9-9l291.2 14c5.8.3 10.3 4.7 10.4 10.2 0 .2.3.3.3.5l-10.1 199.1z"
            />
            <path
                d="M384 256c17.6 0 32-14.4 32-32s-14.3-32-32-32c-17.6 0-32 14.3-32 32s14.3 32 32 32z"
            />
        </symbol>

        <symbol
            id="icon-product-pricing"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm6-6H7q-.425 0-.712.288T6 15q0 .425.288.713T7 16h1q0 .425.288.713T9 17q.425 0 .713-.288T10 16h1q.425 0 .713-.288T12 15v-3q0-.425-.288-.712T11 11H8v-1h3q.425 0 .713-.288T12 9q0-.425-.288-.712T11 8h-1q0-.425-.288-.712T9 7q-.4 0-.562.363T8 8H7q-.425 0-.712.288T6 9v3q0 .425.288.713T7 13h3zm6.175 2.075l1.4-1.4q.125-.125.063-.275t-.238-.15h-2.8q-.175 0-.237.15t.062.275l1.4 1.4q.075.075.175.075t.175-.075M14.6 10h2.8q.175 0 .238-.15t-.063-.275l-1.4-1.4Q16.1 8.1 16 8.1t-.175.075l-1.4 1.4q-.125.125-.062.275t.237.15"
            />
        </symbol>

        <symbol
            id="icon-product-attributes"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                d="M17.75 20.25q-1.575 0-2.662-1.088T14 16.5q0-1.575 1.088-2.662t2.662-1.088q1.575 0 2.663 1.088T21.5 16.5q0 1.575-1.088 2.663T17.75 20.25M11 17.5H5q-.425 0-.712-.288T4 16.5q0-.425.288-.712T5 15.5h6q.425 0 .713.288T12 16.5q0 .425-.288.713T11 17.5m-4.75-6.25q-1.575 0-2.662-1.088T2.5 7.5q0-1.575 1.088-2.662T6.25 3.75q1.575 0 2.663 1.088T10 7.5q0 1.575-1.088 2.663T6.25 11.25M19 8.5h-6q-.425 0-.712-.288T12 7.5q0-.425.288-.712T13 6.5h6q.425 0 .713.288T20 7.5q0 .425-.288.713T19 8.5"
            ></path>
        </symbol>

        <symbol
            id="icon-product-content"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                d="M12 19q1.675 0 2.838-1.175T16 15v-3q0-.425-.288-.712T15 11q-.425 0-.712.288T14 12v3q0 .825-.575 1.413T12 17q-.825 0-1.412-.587T10 15V9.5q0-.225.15-.363T10.5 9q.225 0 .363.138T11 9.5V14q0 .425.288.713T12 15q.425 0 .713-.288T13 14V9.5q0-1.05-.725-1.775T10.5 7q-1.05 0-1.775.725T8 9.5V15q0 1.65 1.175 2.825T12 19m-6 3q-.825 0-1.412-.587T4 20V4q0-.825.588-1.412T6 2h9l5 5v13q0 .825-.587 1.413T18 22zm8-18v3q0 .425.288.713T15 8h3z"
            />
        </symbol>

        <symbol id="icon-arrow-left" viewBox="0 0 20 20">
            <path
                clip-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-arrow-long-left"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-arrow-right" viewBox="0 0 20 20">
            <path
                clip-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-arrow-long-right"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-arrow-top-right-on-square"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-arrow-up-tray" viewBox="0 0 20 20">
            <path
                d="M9.3 13.3a.8.8 0 0 0 1.4 0V4.6l3 3.2a.8.8 0 0 0 1.1-1l-4.3-4.6a.8.8 0 0 0-1 0L5.2 6.7a.8.8 0 1 0 1 1l3-3v8.6z"
            />
            <path
                d="M3.5 12.8a.8.8 0 0 0-1.5 0v2.4A2.8 2.8 0 0 0 4.8 18h10.4a2.8 2.8 0 0 0 2.8-2.8v-2.4a.8.8 0 0 0-1.5 0v2.4c0 .7-.6 1.3-1.3 1.3H4.8c-.7 0-1.3-.6-1.3-1.3v-2.4z"
            />
        </symbol>

        <symbol
            id="icon-cloud-arrow-up"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-bars-arrow-up"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-bars-arrow-down"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-chevron-up-down"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-chevron-up"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-chevron-down"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-chevron-right"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-xmark"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M6 18L18 6M6 6l12 12"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-bars-3"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol
            id="icon-bars-3-center-left"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-google" fill="currentColor" viewBox="0 0 1024 1024">
            <path
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm167 633.6C638.4 735 583 757 516.9 757c-95.7 0-178.5-54.9-218.8-134.9C281.5 589 272 551.6 272 512s9.5-77 26.1-110.1c40.3-80.1 123.1-135 218.8-135c66 0 121.4 24.3 163.9 63.8L610.6 401c-25.4-24.3-57.7-36.6-93.6-36.6c-63.8 0-117.8 43.1-137.1 101c-4.9 14.7-7.7 30.4-7.7 46.6s2.8 31.9 7.7 46.6c19.3 57.9 73.3 101 137 101c33 0 61-8.7 82.9-23.4c26-17.4 43.2-43.3 48.9-74H516.9v-94.8h230.7c2.9 16.1 4.4 32.8 4.4 50.1c0 74.7-26.7 137.4-73 180.1z"
            />
        </symbol>

        <symbol id="icon-google-logo" viewBox="0 0 256 262">
            <path
                d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                fill="#4285F4"
            />
            <path
                d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                fill="#34A853"
            />
            <path
                d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"
                fill="#FBBC05"
            />
            <path
                d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                fill="#EB4335"
            />
        </symbol>

        <symbol id="icon-envelope" viewBox="0 0 24 24" fill="currentColor">
            <path
                d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z"
            />
        </symbol>

        <!-- <symbol id="icon-facebook" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M24 12.07a12 12 0 1 0-13.88 11.86v-8.39H7.08v-3.47h3.05V9.43c0-3 1.79-4.67 4.53-4.67 1.31 0 2.68.24 2.68.24v2.95h-1.51c-1.5 0-1.96.92-1.96 1.87v2.25h3.33l-.53 3.47h-2.8v8.39A12 12 0 0 0 24 12.07z"
            />
        </symbol> -->

        <symbol id="icon-facebook" viewBox="0 0 24 24" fill="currentColor">
            <path
                d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4z"
            />
        </symbol>

        <symbol id="icon-twitter" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M24 4.6a10 10 0 0 1-2.9.7 5 5 0 0 0 2.2-2.7c-1 .6-2 1-3.1 1.2a5 5 0 0 0-8.4 4.5A14 14 0 0 1 1.6 3.2 4.8 4.8 0 0 0 1 5.6a5 5 0 0 0 2.2 4.1 4.9 4.9 0 0 1-2.3-.6A5 5 0 0 0 5 14a5 5 0 0 1-2.2 0 5 5 0 0 0 4.6 3.5 9.9 9.9 0 0 1-6.1 2.1H0a14 14 0 0 0 7.6 2.2c9 0 14-7.5 14-14V7A10 10 0 0 0 24 4.6z"
            />
        </symbol>

        <symbol id="icon-x" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231zm-1.161 17.52h1.833L7.045 4.126H5.078z"
            />
        </symbol>

        <symbol id="icon-snapchat" fill="currentColor" viewBox="0 0 256 256">
            <path
                d="M245.47 189.74c-7.1 6.67-17.67 7.71-27.88 8.72c-6.31.62-12.83 1.27-16.39 3.23c-3.37 1.86-6.85 6.62-10.21 11.22c-5.4 7.41-11.53 15.8-21.23 18.28a26.35 26.35 0 0 1-6.64.81c-6.88 0-13.7-2.32-19.9-4.43c-5.55-1.89-10.8-3.68-15.21-3.68s-9.66 1.79-15.21 3.68c-8.19 2.79-17.47 6-26.54 3.62c-9.71-2.48-15.84-10.87-21.24-18.28c-3.36-4.6-6.84-9.36-10.21-11.22c-3.56-2-10.08-2.61-16.38-3.23c-10.22-1-20.79-2.05-27.89-8.72a8 8 0 0 1 2.77-13.36c.09 0 12.84-4.86 25.36-19a94 94 0 0 0 17.74-30.2L37 119.43a8 8 0 1 1 6-14.86l17.85 7.15A151.24 151.24 0 0 0 64 80a64 64 0 0 1 128 0a149 149 0 0 0 3.21 31.73l17.79-7.16a8 8 0 1 1 6 14.86l-19.3 7.72c14.08 38.35 42.64 49.09 43 49.23a8 8 0 0 1 2.77 13.36"
            />
        </symbol>

        <symbol id="icon-discord" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M19.303 5.337A17.32 17.32 0 0 0 14.963 4c-.191.329-.403.775-.552 1.125a16.592 16.592 0 0 0-4.808 0C9.454 4.775 9.23 4.329 9.05 4a17.075 17.075 0 0 0-4.342 1.337C1.961 9.391 1.218 13.35 1.59 17.255a17.69 17.69 0 0 0 5.318 2.664a12.94 12.94 0 0 0 1.136-1.836c-.627-.234-1.22-.52-1.794-.86c.149-.106.297-.223.435-.34c3.46 1.582 7.207 1.582 10.624 0c.149.117.287.234.435.34c-.573.34-1.167.626-1.793.86a12.94 12.94 0 0 0 1.135 1.836a17.594 17.594 0 0 0 5.318-2.664c.457-4.52-.722-8.448-3.1-11.918M8.52 14.846c-1.04 0-1.889-.945-1.889-2.101c0-1.157.828-2.102 1.89-2.102c1.05 0 1.91.945 1.888 2.102c0 1.156-.838 2.1-1.889 2.1m6.974 0c-1.04 0-1.89-.945-1.89-2.101c0-1.157.828-2.102 1.89-2.102c1.05 0 1.91.945 1.889 2.102c0 1.156-.828 2.1-1.89 2.1"
            />
        </symbol>

        <symbol id="icon-telegram" fill="currentColor" viewBox="0 0 496 512">
            <path
                d="M248 8C111.033 8 0 119.033 0 256s111.033 248 248 248s248-111.033 248-248S384.967 8 248 8m114.952 168.66c-3.732 39.215-19.881 134.378-28.1 178.3c-3.476 18.584-10.322 24.816-16.948 25.425c-14.4 1.326-25.338-9.517-39.287-18.661c-21.827-14.308-34.158-23.215-55.346-37.177c-24.485-16.135-8.612-25 5.342-39.5c3.652-3.793 67.107-61.51 68.335-66.746c.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608 69.142q-14.845 10.194-26.894 9.934c-8.855-.191-25.888-5.006-38.551-9.123c-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7 18.45-13.7q108.446-47.248 144.628-62.3c68.872-28.647 83.183-33.623 92.511-33.789c2.052-.034 6.639.474 9.61 2.885a10.452 10.452 0 0 1 3.53 6.716a43.765 43.765 0 0 1 .417 9.769"
            />
        </symbol>

        <symbol id="icon-slack" fill="currentColor" viewBox="0 0 128 128">
            <path
                d="M27.15 80.766c0 7.351-5.994 13.355-13.345 13.355C6.454 94.12.45 88.117.45 80.766c0-7.351 6.004-13.355 13.355-13.355H27.15zm6.73 0c0-7.351 6.003-13.355 13.354-13.355c7.351 0 13.355 6.004 13.355 13.355v33.43c0 7.35-6.004 13.354-13.355 13.354c-7.351 0-13.355-6.004-13.355-13.355v-33.43zM47.234 27.15c-7.351 0-13.355-5.994-13.355-13.345C33.88 6.454 39.883.45 47.234.45c7.351 0 13.355 6.004 13.355 13.355V27.15zm0 6.73c7.351 0 13.355 6.003 13.355 13.354c0 7.351-6.004 13.355-13.355 13.355h-33.43C6.455 60.589.45 54.585.45 47.234c0-7.351 6.004-13.355 13.355-13.355zm53.616 13.354c0-7.351 5.994-13.355 13.345-13.355c7.351 0 13.355 6.004 13.355 13.355c0 7.351-6.004 13.355-13.355 13.355H100.85zm-6.73 0c0 7.351-6.003 13.355-13.354 13.355c-7.351 0-13.355-6.004-13.355-13.355v-33.43C67.411 6.455 73.415.45 80.766.45c7.351 0 13.355 6.004 13.355 13.355zM80.766 100.85c7.351 0 13.355 5.994 13.355 13.345c0 7.351-6.004 13.355-13.355 13.355c-7.351 0-13.355-6.004-13.355-13.355V100.85zm0-6.73c-7.351 0-13.355-6.003-13.355-13.354c0-7.351 6.004-13.355 13.355-13.355h33.43c7.35 0 13.354 6.004 13.354 13.355c0 7.351-6.004 13.355-13.355 13.355zm0 0"
            />
        </symbol>

        <symbol id="icon-slack-logo" viewBox="0 0 128 128">
            <path
                d="M27.255 80.719c0 7.33-5.978 13.317-13.309 13.317C6.616 94.036.63 88.049.63 80.719s5.987-13.317 13.317-13.317h13.309zm6.709 0c0-7.33 5.987-13.317 13.317-13.317s13.317 5.986 13.317 13.317v33.335c0 7.33-5.986 13.317-13.317 13.317c-7.33 0-13.317-5.987-13.317-13.317zm0 0"
                fill="#de1c59"
            />
            <path
                d="M47.281 27.255c-7.33 0-13.317-5.978-13.317-13.309C33.964 6.616 39.951.63 47.281.63s13.317 5.987 13.317 13.317v13.309zm0 6.709c7.33 0 13.317 5.987 13.317 13.317s-5.986 13.317-13.317 13.317H13.946C6.616 60.598.63 54.612.63 47.281c0-7.33 5.987-13.317 13.317-13.317zm0 0"
                fill="#35c5f0"
            />
            <path
                d="M100.745 47.281c0-7.33 5.978-13.317 13.309-13.317c7.33 0 13.317 5.987 13.317 13.317s-5.987 13.317-13.317 13.317h-13.309zm-6.709 0c0 7.33-5.987 13.317-13.317 13.317s-13.317-5.986-13.317-13.317V13.946C67.402 6.616 73.388.63 80.719.63c7.33 0 13.317 5.987 13.317 13.317zm0 0"
                fill="#2eb57d"
            />
            <path
                d="M80.719 100.745c7.33 0 13.317 5.978 13.317 13.309c0 7.33-5.987 13.317-13.317 13.317s-13.317-5.987-13.317-13.317v-13.309zm0-6.709c-7.33 0-13.317-5.987-13.317-13.317s5.986-13.317 13.317-13.317h33.335c7.33 0 13.317 5.986 13.317 13.317c0 7.33-5.987 13.317-13.317 13.317zm0 0"
                fill="#ebb02e"
            />
        </symbol>

        <symbol id="icon-youtube" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M23.5 6.19a3.02 3.02 0 0 0-2.12-2.14c-1.88-.5-9.38-.5-9.38-.5s-7.5 0-9.38.5A3.02 3.02 0 0 0 .5 6.19C0 8.07 0 12 0 12s0 3.93.5 5.81a3.02 3.02 0 0 0 2.12 2.14c1.87.5 9.38.5 9.38.5s7.5 0 9.38-.5a3.02 3.02 0 0 0 2.12-2.14C24 15.93 24 12 24 12s0-3.93-.5-5.81zM9.54 15.57V8.43L15.82 12l-6.28 3.57z"
            />
        </symbol>

        <symbol id="icon-instagram" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M12 0C8.74 0 8.33.01 7.05.07a8.85 8.85 0 0 0-2.91.56c-.79.3-1.46.72-2.13 1.38S.94 3.35.63 4.14c-.3.77-.5 1.64-.56 2.91C.01 8.33 0 8.74 0 12s.01 3.67.07 4.95c.06 1.27.26 2.15.56 2.91.3.79.72 1.46 1.38 2.13a5.87 5.87 0 0 0 2.13 1.38c.77.3 1.64.5 2.91.56 1.28.06 1.69.07 4.95.07s3.67-.01 4.95-.07a8.88 8.88 0 0 0 2.91-.56 5.9 5.9 0 0 0 2.13-1.38 5.86 5.86 0 0 0 1.38-2.13c.3-.77.5-1.64.56-2.91.06-1.28.07-1.69.07-4.95s-.01-3.67-.07-4.95a8.87 8.87 0 0 0-.56-2.91 5.89 5.89 0 0 0-1.38-2.13A5.85 5.85 0 0 0 19.86.63c-.77-.3-1.64-.5-2.91-.56A83.63 83.63 0 0 0 12 0zm0 2.16c3.2 0 3.58.02 4.85.07 1.17.06 1.8.25 2.23.42.56.21.96.47 1.38.9.42.41.68.81.9 1.37.16.43.36 1.06.4 2.23.07 1.27.08 1.65.08 4.85s-.02 3.58-.08 4.85a6.75 6.75 0 0 1-.42 2.23c-.22.56-.48.96-.9 1.38-.41.42-.82.68-1.38.9-.42.16-1.06.36-2.23.4-1.27.07-1.65.08-4.86.08-3.21 0-3.59-.02-4.86-.08a6.8 6.8 0 0 1-2.23-.42 3.72 3.72 0 0 1-1.38-.9 3.64 3.64 0 0 1-.9-1.38 6.81 6.81 0 0 1-.42-2.23c-.05-1.26-.06-1.65-.06-4.84 0-3.2.01-3.6.06-4.87A6.8 6.8 0 0 1 2.6 4.9c.2-.57.48-.96.9-1.38.42-.42.8-.69 1.38-.9A6.64 6.64 0 0 1 7.1 2.2c1.27-.04 1.65-.06 4.86-.06l.04.03zm0 3.68a6.16 6.16 0 1 0 0 12.32 6.16 6.16 0 0 0 0-12.32zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm7.85-10.4a1.44 1.44 0 1 1-2.89 0 1.44 1.44 0 0 1 2.89 0z"
            />
        </symbol>

        <symbol id="icon-instagram-logo" viewBox="0 0 256 256">
            <g fill="none">
                <rect fill="url(#a)" height="256" rx="60" width="256" />
                <rect fill="url(#b)" height="256" rx="60" width="256" />
                <path
                    d="M128 28c-27.1 0-30.6.1-41.2.6-10.7.5-18 2.2-24.3 4.6a49 49 0 0 0-17.7 11.6 49 49 0 0 0-11.6 17.7 73 73 0 0 0-4.6 24.3c-.5 10.6-.6 14-.6 41.2s.1 30.6.6 41.2c.5 10.7 2.2 18 4.6 24.3a49 49 0 0 0 11.6 17.7 49 49 0 0 0 17.7 11.6 73.5 73.5 0 0 0 24.3 4.6c10.6.5 14 .6 41.2.6 27.2 0 30.6-.1 41.2-.6 10.7-.5 18-2.2 24.3-4.6a49 49 0 0 0 17.7-11.6 49 49 0 0 0 11.5-17.7 74.1 74.1 0 0 0 4.7-24.3c.5-10.6.6-14 .6-41.2s-.1-30.6-.6-41.2a74 74 0 0 0-4.7-24.3 49 49 0 0 0-11.5-17.7c-5.6-5.6-11.1-9-17.7-11.5a73.6 73.6 0 0 0-24.3-4.7A710 710 0 0 0 128 28zm-9 18h9c26.7 0 29.9.1 40.4.6 9.8.4 15 2 18.6 3.4 4.7 1.9 8 4 11.5 7.5A31 31 0 0 1 206 69c1.3 3.5 3 8.8 3.4 18.6.5 10.5.6 13.7.6 40.4s-.1 29.8-.6 40.4c-.4 9.7-2 15-3.4 18.5a31 31 0 0 1-7.5 11.5A31 31 0 0 1 187 206c-3.5 1.4-8.8 3-18.6 3.5a696 696 0 0 1-40.4.5 696 696 0 0 1-40.4-.5c-9.7-.5-15-2.1-18.6-3.5-4.6-1.8-8-4-11.5-7.5S52 191.6 50 187c-1.3-3.5-3-8.8-3.4-18.5-.5-10.6-.6-13.7-.6-40.4 0-26.8.1-29.9.6-40.4.4-9.8 2-15 3.4-18.6 1.9-4.7 4-8 7.5-11.5A31 31 0 0 1 69 50c3.6-1.4 8.9-3 18.6-3.4 9.2-.5 12.8-.6 31.4-.6zm62.4 16.6a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-53.4 14a51.4 51.4 0 1 0 0 102.8 51.4 51.4 0 0 0 0-102.8zm0 18a33.3 33.3 0 1 1 0 66.7 33.3 33.3 0 0 1 0-66.6"
                    fill="#fff"
                />
                <defs>
                    <radialGradient
                        id="a"
                        cx="0"
                        cy="0"
                        gradientTransform="matrix(0 -254 236 0 68 275.7)"
                        gradientUnits="userSpaceOnUse"
                        r="1"
                    >
                        <stop stop-color="#FD5" />
                        <stop offset=".1" stop-color="#FD5" />
                        <stop offset=".5" stop-color="#FF543E" />
                        <stop offset="1" stop-color="#C837AB" />
                    </radialGradient>
                    <radialGradient
                        id="b"
                        cx="0"
                        cy="0"
                        gradientTransform="matrix(22 111 -458 92 -42.9 18.4)"
                        gradientUnits="userSpaceOnUse"
                        r="1"
                    >
                        <stop stop-color="#3771C8" />
                        <stop offset=".1" stop-color="#3771C8" />
                        <stop offset="1" stop-color="#60F" stop-opacity="0" />
                    </radialGradient>
                </defs>
            </g>
        </symbol>

        <symbol id="icon-whatsapp" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M17.47 14.38c-.3-.15-1.76-.87-2.03-.97-.27-.1-.47-.14-.67.15-.2.3-.76.97-.94 1.17-.17.2-.34.22-.64.07s-1.26-.46-2.4-1.47a8.95 8.95 0 0 1-1.64-2.06c-.18-.3-.02-.46.13-.6.13-.14.3-.35.44-.53.15-.17.2-.3.3-.5.1-.2.05-.36-.03-.51l-.91-2.21c-.24-.58-.49-.5-.67-.51l-.57-.01c-.2 0-.52.07-.8.37-.26.3-1.03 1.02-1.03 2.48s1.06 2.88 1.21 3.08c.15.2 2.1 3.2 5.08 4.48.7.3 1.26.5 1.7.63.7.22 1.35.2 1.86.11.57-.08 1.76-.71 2-1.4.26-.7.26-1.3.18-1.42-.07-.13-.27-.2-.57-.35m-5.42 7.4A9.87 9.87 0 0 1 7 20.41l-.36-.22-3.74.99 1-3.65-.23-.38a9.86 9.86 0 0 1-1.51-5.26 9.9 9.9 0 0 1 16.87-6.98 9.82 9.82 0 0 1 2.9 7 9.9 9.9 0 0 1-9.89 9.88m8.41-18.3A11.81 11.81 0 0 0 12.05 0a11.91 11.91 0 0 0-10.3 17.84L.05 24l6.31-1.65a11.88 11.88 0 0 0 5.68 1.44h.01c6.55 0 11.89-5.33 11.9-11.89a11.82 11.82 0 0 0-3.49-8.41Z"
            />
        </symbol>

        <symbol id="icon-tiktok" fill="currentColor" viewBox="0 0 448 512">
            <path
                d="M448 209.91a210.06 210.06 0 0 1-122.77-39.25v178.72A162.55 162.55 0 1 1 185 188.31v89.89a74.62 74.62 0 1 0 52.23 71.18V0h88a121.18 121.18 0 0 0 1.86 22.17A122.18 122.18 0 0 0 381 102.39a121.43 121.43 0 0 0 67 20.14Z"
            />
        </symbol>

        <symbol id="icon-tiktok-logo" viewBox="0 0 256 290">
            <path
                d="M189.7 104.4a113.6 113.6 0 0 0 66.3 21.2V78.1a67.1 67.1 0 0 1-14-1.5V114c-24.6 0-47.5-7.8-66.2-21.1v97a87.9 87.9 0 0 1-136.8 73 87.9 87.9 0 0 0 150.8-61.4v-97zm17.2-48a66.2 66.2 0 0 1-17.2-38.7v-6.1h-13.2A66.5 66.5 0 0 0 207 56.5M69.7 225.6a40 40 0 0 1-8.2-24.3c0-22.2 18-40.2 40.2-40.2a40.3 40.3 0 0 1 12.2 1.9v-48.6c-4.6-.7-9.3-1-14-.8v37.8a40.3 40.3 0 0 0-12.1-1.9 40.2 40.2 0 0 0-18.1 76.1"
                fill="#FF004F"
            />
            <path
                d="M175.8 92.8a113.6 113.6 0 0 0 66.3 21.2V76.6c-13.8-3-26-10.1-35.2-20.1-15.7-9.8-27-26-30.4-45H142v190a40.2 40.2 0 0 1-72.2 24.1 40.2 40.2 0 0 1 30.3-74.2v-37.8a87.9 87.9 0 0 0-61 149.2 87.9 87.9 0 0 0 136.8-73z"
            />
            <path
                d="M242 76.6v-10A66.3 66.3 0 0 1 207 56.4 66.5 66.5 0 0 0 242 76.6m-65.6-65a67.8 67.8 0 0 1-.7-5.5V0H128v189.8c-.1 22.2-18 40-40.2 40a40 40 0 0 1-18.1-4.2 40.2 40.2 0 0 0 72.2-24.2V11.6zm-76.5 102v-10.8a88.8 88.8 0 0 0-12.1-.8 87.9 87.9 0 0 0-49 160.8 87.9 87.9 0 0 1 61-149.3"
                fill="#00F2EA"
            />
        </symbol>

        <symbol id="icon-quora" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M7.38.95a11.96 11.96 0 0 1 13.87 18.59l2.4 2.42c.74.74.22 2-.82 2H12.12a12.52 12.52 0 0 1-.3 0h-.02A11.96 11.96 0 0 1 7.38.95Zm7.32 4.43a7.17 7.17 0 1 0-5.49 13.25 7.17 7.17 0 0 0 5.5-13.25Z"
            />
        </symbol>

        <!-- <symbol id="icon-linkedin" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM9 17H6.477v-7H9v7zM7.694 8.717c-.771 0-1.286-.514-1.286-1.2s.514-1.2 1.371-1.2c.771 0 1.286.514 1.286 1.2s-.514 1.2-1.371 1.2zM18 17h-2.442v-3.826c0-1.058-.651-1.302-.895-1.302s-1.058.163-1.058 1.302V17h-2.523v-7h2.523v.977c.325-.57.976-.977 2.197-.977S18 10.977 18 13.174V17z"
            />
        </symbol> -->

        <symbol id="icon-linkedin" viewBox="0 0 24 24" fill="currentColor">
            <path
                d="M6.94 5a2 2 0 1 1-4-.002a2 2 0 0 1 4 .002M7 8.48H3V21h4zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91z"
            />
        </symbol>

        <symbol id="icon-reddit" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.74c.69 0 1.25.56 1.25 1.25a1.25 1.25 0 0 1-2.5.06l-2.6-.55-.8 3.75c1.83.07 3.48.63 4.68 1.49.3-.31.73-.5 1.2-.5.97 0 1.76.8 1.76 1.76 0 .72-.43 1.33-1.01 1.61a3.11 3.11 0 0 1 .04.52c0 2.7-3.13 4.87-7 4.87-3.88 0-7-2.17-7-4.87 0-.18 0-.36.04-.53A1.75 1.75 0 0 1 4.03 12a1.75 1.75 0 0 1 2.96-1.26 8.52 8.52 0 0 1 4.74-1.5l.89-4.17a.34.34 0 0 1 .14-.2.35.35 0 0 1 .24-.04l2.9.62a1.21 1.21 0 0 1 1.11-.7zM9.25 12a1.25 1.25 0 1 0 1.25 1.25c0-.69-.56-1.25-1.25-1.25zm5.5 0a1.25 1.25 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm-5.47 3.99a.33.33 0 0 0-.23.1.33.33 0 0 0 0 .46c.84.84 2.49.91 2.96.91.48 0 2.1-.06 2.96-.91a.36.36 0 0 0 .03-.47.33.33 0 0 0-.46 0c-.55.54-1.68.73-2.51.73-.83 0-1.98-.2-2.51-.73a.33.33 0 0 0-.24-.1z"
            />
        </symbol>

        <symbol
            id="icon-magnifying-glass"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-magnifying-glass-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M22 22L20 20"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
        </symbol>

        <symbol id="icon-checklist" fill="currentColor" viewBox="0 0 16 16">
            <path
                d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"
            />
            <path
                d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"
            />
        </symbol>

        <symbol
            id="icon-shield-check-solid"
            fill="currentColor"
            viewBox="0 0 24 24"
        >
            <path
                clip-rule="evenodd"
                d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol
            id="icon-palette-outline"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M12 22q-2.05 0-3.875-.788t-3.187-2.15q-1.363-1.362-2.15-3.187T2 12q0-2.075.813-3.9t2.2-3.175Q6.4 3.575 8.25 2.788T12.2 2q2 0 3.775.688t3.113 1.9q1.337 1.212 2.125 2.875T22 11.05q0 2.875-1.75 4.413T16 17h-1.85q-.225 0-.312.125t-.088.275q0 .3.375.863t.375 1.287q0 1.25-.687 1.85T12 22m-5.5-9q.65 0 1.075-.425T8 11.5q0-.65-.425-1.075T6.5 10q-.65 0-1.075.425T5 11.5q0 .65.425 1.075T6.5 13m3-4q.65 0 1.075-.425T11 7.5q0-.65-.425-1.075T9.5 6q-.65 0-1.075.425T8 7.5q0 .65.425 1.075T9.5 9m5 0q.65 0 1.075-.425T16 7.5q0-.65-.425-1.075T14.5 6q-.65 0-1.075.425T13 7.5q0 .65.425 1.075T14.5 9m3 4q.65 0 1.075-.425T19 11.5q0-.65-.425-1.075T17.5 10q-.65 0-1.075.425T16 11.5q0 .65.425 1.075T17.5 13M12 20q.225 0 .363-.125t.137-.325q0-.35-.375-.825T11.75 17.3q0-1.05.725-1.675T14.25 15H16q1.65 0 2.825-.962T20 11.05q0-3.025-2.312-5.038T12.2 4Q8.8 4 6.4 6.325T4 12q0 3.325 2.338 5.663T12 20"
            />
        </symbol>

        <symbol
            id="icon-store-clock-outline"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M18 4H2V2h16zm-.5 9H16v5l3.61 2.16l.75-1.22l-2.86-1.69zm6.5 4c0 3.87-3.13 7-7 7c-3.53 0-6.43-2.61-6.92-6H2v-6H1v-2l1-5h16l1 5v.29c2.89.87 5 3.54 5 6.71M3.04 10h13.92l-.6-3H3.64zM4 16h6v-4H4zm18 1c0-2.76-2.24-5-5-5s-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5"
            />
        </symbol>

        <symbol
            id="icon-check"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
            <path
                d="M4.5 12.75l6 6 9-13.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </symbol>

        <symbol id="icon-check-circle" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            />
        </symbol>

        <symbol id="icon-circle" fill="none" viewBox="0 0 24 24">
            <path
                d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                stroke="#000"
                stroke-width="1.5"
            />
        </symbol>

        <symbol id="icon-circle" viewBox="0 0 24 24">
            <path
                clip-rule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                fill-rule="evenodd"
            />
        </symbol>

        <symbol id="icon-queue-list-solid" viewBox="0 0 24 24">
            <path
                d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z"
            />
        </symbol>

        <symbol id="icon-list-video" viewBox="0 0 24 24">
            <path
                d="M12 12H3m13-6H3m9 12H3m13-6l5 3l-5 3v-6Z"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
            />
        </symbol>

        <symbol id="icon-spinner" fill="none" viewBox="0 0 24 24">
            <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
            />
            <path
                class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"
            />
        </symbol>

        <symbol
            id="uploadcare--icon-files"
            class="size-5"
            fill="currentColor"
            viewBox="0 0 20 20"
        >
            <path
                d="M4.75 3A1.75 1.75 0 003 4.75v2.752l.104-.002h13.792c.035 0 .07 0 .104.002V6.75A1.75 1.75 0 0015.25 5h-3.836a.25.25 0 01-.177-.073L9.823 3.513A1.75 1.75 0 008.586 3H4.75zM3.104 9a1.75 1.75 0 00-1.673 2.265l1.385 4.5A1.75 1.75 0 004.488 17h11.023a1.75 1.75 0 001.673-1.235l1.384-4.5A1.75 1.75 0 0016.896 9H3.104z"
            />
        </symbol>
    </svg>
</template>
<script setup></script>
